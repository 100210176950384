tr {
    border-top: 1px solid #c6cbd1;
    background: #000;
  }
  
  th,
  td {
    padding: 6px 13px;
    border: 1px solid #39393a;
  }
  
  table tr:nth-child(2n) {
    background: #222222;
  }


